import React, {PureComponent} from 'react';

import Flex from '../../atoms/flex';
import Logo from '../../../assets/images/logo.png';
import SweetLogo from '../../../assets/images/sweetaudiosuite.png';

export default class Header extends PureComponent {
  render() {
    return (
      <Flex spaced="between" row>
        <img src={Logo} alt='capitalofmedia' style={{height: '45px'}} />
        <div style={{overflow: 'hidden'}}>
          <img src={SweetLogo} alt="sweetaudiosuite" style={{height: '85px', marginTop: '-18px', marginRight: '-72px'}} />
        </div>
      </Flex>
    );
  }
}
