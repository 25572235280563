import React, {PureComponent} from 'react';

/**
 * Text component
 */
export default class Text extends PureComponent {
  /**
   * @private
   * @return {Object}
   */
  _buildStyle() {
    const style = {};
    const weight = this.props.bold ? 'bold' : 'normal';
    style.fontFamily = `Roboto, sans-serif`;
    style.fontWeight = weight;
    if (this.props.size) {
      style.fontSize = `${this.props.size}px`;
    } else {
      style.fontSize = '14px';
    }
    if (this.props.color) {
      style.color = this.props.color;
    } else {
      style.color = '#868888';
    }
    style.margin = 0;
    if (this.props.center) {
      style.textAlign = 'center';
    }
    if (this.props.right) {
      style.textAlign = 'right';
    }
    if (this.props.italic) {
      style.fontStyle = 'italic';
    }
    style.wordBreak = 'break-word';
    return style;
  }

  /**
   * @return {*}
   * @private
   */
  _getComponent() {
    let child = this.props.text || this.props.children;
    if (this.props.heading) {
      return (
        <h2 style={this._buildStyle()}>{child}</h2>
      );
    } else {
      return (
        <span style={this._buildStyle()}>{child}</span>
      );
    }
  }

  /**
   * @return {*}
   */
  render() {
    if (this.props.block) {
      return (
        <div>{this._getComponent()}</div>
      );
    } else {
      return this._getComponent();
    }
  }
}
