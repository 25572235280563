import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import ApiService from '../../lib/ApiService';
import SessionManager from '../../lib/SessionManager';

import SweetLogo from '../../assets/images/sweetaudiosuite_with icon.png';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const fields = {
  email: '',
  password: '',
};

function handleChange(field, e) {
  fields[field] = e.target.value;
}

function login() {
  const service = new ApiService();
  service.post('/login', fields)
    .then((response) => {
      if (response) {
        SessionManager.setSession(response, false);
        window.location.href = '/';
      } else {
        alert('Invalid username or password');
      }
    });
}

export default function SignIn() {
  const classes = useStyles();
  if (!SessionManager.getSession().isGuest()) {
    window.location.href = '/';
    return null;
  }
  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div>
            <img style={{height: '85px'}} src={SweetLogo} alt="Sweet Audio Suite"/>
          </div>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Email Address"
              onChange={handleChange.bind(null, 'email')}
              autoFocus />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Password"
              type="password"
              onChange={handleChange.bind(null, 'password')} />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={login}
              className={classes.submit} >
              Sign In
            </Button>
          </form>
        </div>
      </Container>
  );
}
