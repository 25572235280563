import React, {PureComponent} from 'react';

import clsx from 'clsx'
import { withStyles } from '@material-ui/styles';
import {Drawer, AppBar, Toolbar, IconButton, Typography} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import StorageIcon from '@material-ui/icons/Storage';
import HistoryIcon from '@material-ui/icons/History';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SessionManager from "../../lib/SessionManager";

import {RedirectManager, routes} from "../../system/RedirectManager";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});


class AdminScene extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
    };
  }

  _toggleDrawer(isOpen) {
    this.setState({
      drawerOpen: isOpen,
    });
  }

  _redirect = (route) => {
    RedirectManager
      .getInstance()
      .redirect(route);
  };

  _onLogout = () => {
    SessionManager.clearSession();
    window.location = '/login';
  };

  render() {
    if (SessionManager.getSession().isGuest()) {
      window.location.href = '/login';
      return null;
    }
    const Fragment = this.props.fragment;
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.state.drawerOpen,
          })}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this._toggleDrawer.bind(this, true)}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: this.state.drawerOpen,
              })}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              SweetAudioSuite
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: this.state.drawerOpen,
            [classes.drawerClose]: !this.state.drawerOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: this.state.drawerOpen,
              [classes.drawerClose]: !this.state.drawerOpen,
            }),
          }}>
          <div className={classes.toolbar}>
            <IconButton onClick={this._toggleDrawer.bind(this, false)}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem button onClick={this._redirect.bind(this, routes.adminUsers)}>
              <ListItemIcon>
                <PeopleAltIcon />
              </ListItemIcon>
              <ListItemText primary="User Management" />
            </ListItem>
            <ListItem button onClick={this._redirect.bind(this, routes.adminSessions)}>
              <ListItemIcon>
                <HistoryIcon />
              </ListItemIcon>
              <ListItemText primary="Saved Sessions" />
            </ListItem>
            <ListItem button onClick={this._redirect.bind(this, routes.adminTempSessions)}>
              <ListItemIcon>
                <StorageIcon />
              </ListItemIcon>
              <ListItemText primary="Temporary Sessions" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button onClick={this._onLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.content}>
          <Toolbar />
          <Fragment redirect={this._redirect} />
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(AdminScene);
