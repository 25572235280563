import React, {PureComponent} from 'react';

/**
 * Sized box
 */
export default class SizedBox extends PureComponent {
  /**
   * @return {*}
   * @private
   */
  _getContainer() {
    const children = this.props.children || null;
    if (this.props.height && this.props.width) {
      return (
        <div
          className="sized-box-block"
          style={{
            width: `${this.props.width}px`,
            height: `${this.props.height}px`,
          }}
        >
          {children}
        </div>
      );
    }
    if (this.props.height) {
      return (
        <div
          className="sized-box-block"
          style={{
            height: `${this.props.height}px`,
          }}
        >
          {children}
        </div>
      );
    }
    if (this.props.width) {
      return (
        <div
          className="sized-box-inline"
          style={{
            width: `${this.props.width}px`,
          }}
        >
          {children}
        </div>
      );
    }
  }

  /**
   * @return {*}
   */
  render() {
    return this._getContainer();
  }
}
