import React, {Component} from 'react';
import {BrowserRouter} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import Router from './Router';

/**
 * App root component
 */
export default class App extends Component {
  /**
   * @return {*}
   */
  render() {
    return (
      <div>
        <CssBaseline />
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </div>
    );
  }
}
