import Utils from './Utils';

/**
 * Singleton event bus for global app events
 */
export default class EventBus {
  /**
   * Init subscribers
   */
  constructor() {
    this.subscribers = {};
  }

  /**
   * @param {string} eventType
   * @param {Function} subscriber
   */
  on(eventType, subscriber) {
    if (!this.subscribers[eventType]) {
      this.subscribers[eventType] = [];
    }
    this.subscribers[eventType].push(subscriber);
  }

  /**
   * @param {string} eventType
   * @param {Function} subscriber
   */
  off(eventType, subscriber) {
    if (!this.subscribers[eventType]) {
      return;
    }
    const index = this.subscribers[eventType].indexOf(subscriber);
    if (index >= 0) {
      this.subscribers[eventType].splice(index, 1);
    }
  }

  /**
   * @param {Event} event
   */
  publish(event) {
    if (!this.subscribers[event.getType()]) {
      return;
    }
    this.subscribers[event.getType()].forEach((subscriber) => {
      Utils.invokeAsync(subscriber, event);
    });
  }
}
