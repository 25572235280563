import React, {PureComponent} from 'react';

import Flex from "../flex";
import SizedBox from "../sized-box";
import { Code } from 'react-content-loader';

export default class ContentLoader extends PureComponent {
  render() {
    return (
      <Flex col>
        <Code style={{width: '500px', maxWidth: '100%'}} />
        <SizedBox height={20} />
        <Code style={{width: '500px', maxWidth: '100%'}} />
      </Flex>
    );
  }
}
