import React, {PureComponent} from 'react';

import ReactTimeAgo from 'react-time-ago';
import {IconButton, Tooltip} from '@material-ui/core';
import en from 'javascript-time-ago/locale/en';
import ReplyIcon from '@material-ui/icons/Reply';
import JavascriptTimeAgo from 'javascript-time-ago';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Text from '../../atoms/text';
import Flex from '../../atoms/flex';
import Utils from '../../../lib/Utils';
import SizedBox from '../../atoms/sized-box';

import './index.scss';

const moment = require('moment');

JavascriptTimeAgo.addLocale(en);

export default class Comments extends PureComponent {
  _getComments(parentId) {
    const comments = [];
    this.props.comments.forEach((comment) => {
      if (!parentId && comment.parentId) {
        return;
      }
      let isSub = false;
      if (parentId) {
        isSub = true;
        if (parentId !== comment.parentId) {
          return;
        }
      }
      const mTime = moment(comment.createdAt);
      const colorProp = {};
      if (comment.completed) {
        colorProp.style = {
          color: '#a5cd39',
        };
      }
      comments.push(
        <div className={`user-comment ${isSub ? 'sub-comment' : ''}`} key={comment.id}>
          <Text text={comment.author} block bold />
          <SizedBox height={10} />
          <Text text={comment.text} block/>
          <SizedBox height={8} />
          <Flex spaced row>
            <Text size={12} italic>
              <span>(</span>
              <span>{Utils.secondsToText(comment.pos)} - </span>
              <ReactTimeAgo date={mTime.toDate()}/>
              <span>)</span>
            </Text>
            <div>
              {comment.parentId === null && (
                <Tooltip title="Seek to Position">
                  <IconButton onClick={this.props.onSeek.bind(null, comment.pos)} style={{padding: '5px'}}>
                    <CenterFocusWeakIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Mark as Done">
                <IconButton onClick={this.props.onCheck.bind(null, comment)} style={{padding: '5px'}}>
                  <CheckCircleOutlineIcon fontSize="small" {...colorProp} />
                </IconButton>
              </Tooltip>
              {comment.parentId === null && (
                <Tooltip title="Reply">
                  <IconButton onClick={this.props.onReply.bind(null, comment)} style={{padding: '5px'}}>
                    <ReplyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Edit">
                <IconButton onClick={this.props.onEdit.bind(null, comment)} style={{padding: '5px'}}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton onClick={this.props.onDelete.bind(null, comment)} style={{padding: '5px'}}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          </Flex>
          <div className="user-comment-sub-comments">
            {!isSub && (
              this._getComments(comment.id)
            )}
          </div>
        </div>
      );
    });
    return comments;
  }

  render() {
    let comments = this._getComments();
    if (comments.length === 0) {
      comments = (
        <div className="user-comment">
          <Text text="No comments" block bold />
        </div>
      );
    }
    return (
      <Flex col fillHeight style={{minWidth: '150px'}}>
        <Flex align="right" row>
          <Text text="comments" size={30} right block bold/>
        </Flex>
        <div className="comments-container">
          <div className="comments">
            {comments}
          </div>
        </div>
      </Flex>
    );
  }
}
