import AppConfig from '../config/config';
import SessionManager from './SessionManager';

import axios from 'axios';

export default class ApiService {
  constructor() {
    this._apiUrl = `${AppConfig.api.url}/api/${AppConfig.api.version}`;
  }

  async upload(servicePath, data) {
    const url = this._buildRequestUrl(servicePath);
    try {
      const response = await axios({
        method: 'post',
        url: url,
        data: data,
      });
      return response.data;
    } catch(e) {
      return null;
    }
  }

  async post(servicePath, data) {
    const url = this._buildRequestUrl(servicePath);
    const headers = {};
    if (!SessionManager.getSession().isGuest()) {
      headers['Authorization'] = `Bearer ${SessionManager.getSession().getToken()}`;
    }
    try {
      const response = await axios({
        method: 'post',
        url: url,
        data: data,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
      });
      return response.data;
    } catch(e) {
      return null;
    }
  }

  async get(servicePath) {
    const url = this._buildRequestUrl(servicePath);
    const headers = {};
    if (!SessionManager.getSession().isGuest()) {
      headers['Authorization'] = `Bearer ${SessionManager.getSession().getToken()}`;
    }
    try {
      const response = await axios({
        method: 'get',
        url: url,
        headers: headers,
      });
      return response.data;
    } catch(e) {
      return null;
    }
  }

  async delete(servicePath) {
    const url = this._buildRequestUrl(servicePath);
    const headers = {};
    if (!SessionManager.getSession().isGuest()) {
      headers['Authorization'] = `Bearer ${SessionManager.getSession().getToken()}`;
    }
    try {
      const response = await axios({
        method: 'delete',
        url: url,
        headers: headers,
      });
      return response.data;
    } catch(e) {
      return null;
    }
  }

  _buildRequestUrl(servicePath) {
    return `${this._apiUrl}${servicePath}`;
  }
}
