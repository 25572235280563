import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';

import {RedirectManager, routes} from './RedirectManager';

/**
 * Base router
 */
class Router extends Component {
  /**
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    RedirectManager
        .getInstance()
        .onRedirect(this.onRoute);
  }

  /**
   * Detach listener
   */
  componentWillUnmount() {
    RedirectManager
        .getInstance()
        .offRedirect(this.onRoute);
  }

  /**
   * @param {Object} obj
   * @return {string}
   * @private
   */
  _buildQuery(obj) {
    const queryArr = [];
    for (const name in obj) {
      if (Object.hasOwnProperty.call(obj, name)) {
        queryArr.push(
            `${encodeURIComponent(name)}=${encodeURIComponent(obj[name])}`);
      }
    }
    return `?${queryArr.join('&')}`;
  }

  /**
   * @param {Event} event
   */
  onRoute = (event) => {
    const details = event.getDetails();
    let withQuery = details.to.path;
    if (details.query) {
      withQuery += this._buildQuery(details.query);
    }
    this.props.history.push(withQuery);
  };

  /**
   * @return {Array.<*>}
   * @private
   */
  _getRoutes() {
    const routeElements = [];
    for (const name in routes) {
      if (Object.hasOwnProperty.call(routes, name)) {
        const route = routes[name];
        const path = route.path;
        const Scene = route.scene;
        const Fragment = route.fragment;
        routeElements.push(
          <Route key={path} exact path={path}>
            <Scene fragment={Fragment} />
          </Route>
        );
      }
    }
    return routeElements;
  }

  /**
   * @return {*}
   */
  render() {
    return (
      <main>
        <Switch>
          {this._getRoutes()}
        </Switch>
      </main>
    );
  }
}

export default withRouter(Router);
