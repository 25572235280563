import React, {PureComponent} from 'react';

import {Tooltip} from '@material-ui/core';

import './index.scss';

export default class MarkerLine extends PureComponent {
  _onMarkerClick = (marker) => {
    if (this.props.onSeek) {
      this.props.onSeek(marker.pos);
    }
  };

  _getMarkers() {
    if (this.props.duration === 0) {
      return null;
    }
    const markers = [];
    this.props.markers.forEach((marker) => {
      if (marker.parentId) {
        return;
      }
      const leftValue = (this.props.width * marker.pos) / this.props.duration;
      markers.push(
        <Tooltip key={marker.id} title={marker.text}>
          <div className="marker-line-marker" style={{left: `${leftValue}px`}} onClick={this._onMarkerClick.bind(this, marker)} />
        </Tooltip>
      );
    });
    return markers;
  }

  render() {
    return (
      <div className="marker-line-container">
        <div className="marker-line-inner-container" style={{width: `${this.props.width}px`, left: `-${this.props.left}px`}}>
          <div className="marker-line-line" />
          <div className="marker-line-markers">
            {this._getMarkers()}
          </div>
        </div>
      </div>
    );
  }
}
