export class Event {
  /**
   * @param event
   */
  constructor(event) {
    this._type = event.type;
    this._event = event;
  }

  getType() {
    return this._type;
  }

  getDetails() {
    return this._event;
  }
}

export class EventFactory {
  /**
   * @param {string} type
   * @param {object} [content]
   * @return {Event}
   */
  static create(type, content) {
    return new Event(Object.assign({
      type: type,
    }, content || {}));
  }
}
