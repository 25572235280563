import React, {PureComponent} from 'react';

import {Tooltip} from '@material-ui/core';
import {PlayArrow, Pause, Stop, SwapHoriz, ZoomIn, SpaceBar, Room, ZoomOut, SkipPrevious} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

import Flex from '../../atoms/flex';
import Text from '../../atoms/text';
import Utils from '../../../lib/Utils';
import SizedBox from '../../atoms/sized-box';
import { EventFactory } from '../../../lib/Event';

export default class Controls extends PureComponent {
  _getIcons() {
    const icons = [
      {title: 'Stop', action: 'controls.stop', icon: Stop},
      {title: 'Back to Beginning', action: 'controls.seekStart', icon: SkipPrevious},
      {title: 'Select', action: 'controls.select', icon: SwapHoriz},
      {title: 'Zoom In', action: 'controls.zoom', icon: ZoomIn},
      {title: 'Zoom Out', action: 'controls.zoomOut', icon: ZoomOut},
      {title: 'Cut', action: 'controls.trim', icon: SpaceBar},
      {title: 'Add Marker', action: 'controls.mark', icon: Room},
    ];
    if (this.props.playing) {
      icons.unshift({title: 'Pause', action: 'controls.pause', icon: Pause});
    } else {
      icons.unshift({title: 'Play', action: 'controls.play', icon: PlayArrow});
    }
    const components = [];
    icons.forEach((icon, i) => {
      const IconComponent = icon.icon;
      components.push(
        <Flex key={icon.action} row>
          <Flex col centered="horizontal">
            <SizedBox height={12} />
            <div style={{borderRadius: '100%', display: 'inline', padding: '1px', border: '2px solid #919191'}}>
              <Tooltip title={icon.title}>
                <IconButton style={{background: '#5D5D5D', padding: '5px'}} onClick={this._onControlClick.bind(this, icon.action)}>
                  <IconComponent style={{color: '#ffffff'}} />
                </IconButton>
              </Tooltip>
            </div>
          </Flex>
          {i !== (icons.length - 1) && (
            <SizedBox width={20} />
          )}
        </Flex>
      );
    });
    return components;
  }

  _onControlClick = (type) => {
    this.props.dispatcher.publish(EventFactory.create('control.action', {
      actionType: type,
    }));
  };

  _getButtonContainer(title, children, action) {
    const textColor = action ? '#ffffff' : '#919191';
    const buttonColor = action ? '#a5cd39' : '#5D5D5D';
    let actionProp = {};
    if (action) {
      actionProp = {
        role: 'button',
        onClick: this._onControlClick.bind(this, action),
      };
    }
    let buttonWidth = '256px';
    let buttonPadding = '8px 0';
    let labelSize = 16;
    if (window.innerWidth < 860) {
      buttonWidth = 'auto';
      buttonPadding = '8px 12px';
      labelSize = 13;
    }
    return (
      <div style={{width: buttonWidth, textAlign: 'center'}}>
        <Text text={title} size={labelSize} color={textColor} block />
        <SizedBox height={12} />
        <div style={{width: '100%', padding: buttonPadding, textAlign: 'center', background: buttonColor, color: '#fff', borderRadius: '17px', cursor: action ? 'pointer' : 'default'}} {...actionProp}>
          {children}
        </div>
      </div>
    );
  }

  render() {
    const additionalProps = {
      row: true,
    };
    if (window.innerWidth < 1740) {
      additionalProps.row = false;
      additionalProps.col = true;
    }
    return (
      <Flex centered="horizontal" {...additionalProps} spaced>
        <Flex col>
          <div style={{textAlign: 'center'}}>
            <div style={{width: '100%', height: '20px', boxSizing: 'border-box', borderBottom: '1px solid #dddddd'}} />
          </div>
          <Flex row>
            {this._getIcons()}
          </Flex>
        </Flex>
        <SizedBox width={10} height={30} />
        <Flex row>
          {this._getButtonContainer('audio position', Utils.secondsToText(this.props.pos))}
          <SizedBox width={10} />
          {this._getButtonContainer('total length', Utils.secondsToText(this.props.duration))}
          <SizedBox width={10} />
          {this._getButtonContainer('save', 'save & share session', 'controls.saveShare')}
        </Flex>
      </Flex>
    );
  }
}
