import React, {PureComponent} from 'react';

import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import Text from '../../atoms/text';
import Flex from '../../atoms/flex';
import {EventFactory} from '../../../lib/Event';
import RelativeParent from '../../atoms/relative-parent';
import SessionManager from '../../../lib/SessionManager';
import './index.scss';

export default class AppMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: null,
      menuAnchorEl: null,
    };
  }

  _handleMenuClick = (type, event) => {
    this.setState({
      openMenu: type,
      menuAnchorEl: event.currentTarget,
    });
  };

  _handleMenuClose = () => {
    this.setState({
      openMenu: null,
      menuAnchorEl: null,
    });
  };

  _onMenuItemClick = (type) => {
    this.props.dispatcher.publish(EventFactory.create('menu.action', {
      actionType: type,
    }));
    this._handleMenuClose();
  };

  render() {
    const hasSession = !SessionManager.getSession().isGuest();
    return (
      <Flex row>
        <div>
          <Button onClick={this._handleMenuClick.bind(this, 'file')}>
            <Text text="file"   />
          </Button>
          <Menu
            keepMounted
            onClose={this._handleMenuClose}
            anchorEl={this.state.menuAnchorEl}
            open={this.state.openMenu === 'file'}>
            {hasSession && (
              <MenuItem onClick={this._onMenuItemClick.bind(this, 'file.new')}>New</MenuItem>
            )}
            {hasSession && (
              <MenuItem onClick={this._onMenuItemClick.bind(this, 'file.open')}>Open</MenuItem>
            )}
            <MenuItem onClick={this._onMenuItemClick.bind(this, 'file.save')}>Save</MenuItem>
            <MenuItem onClick={this._onMenuItemClick.bind(this, 'file.shareSession')}>Share Session URL</MenuItem>
            <MenuItem onClick={this._onMenuItemClick.bind(this, 'file.download')}>Create and Download Audio File</MenuItem>
          </Menu>
        </div>
        <RelativeParent>
          <Button onClick={this._handleMenuClick.bind(this, 'effects')}>
            <Text text="effects" />
          </Button>
          <Menu
            keepMounted
            onClose={this._handleMenuClose}
            anchorEl={this.state.menuAnchorEl}
            open={this.state.openMenu === 'effects'}>
            <MenuItem onClick={this._onMenuItemClick.bind(this, 'effect.fadeIn')}>Fade In</MenuItem>
            <MenuItem onClick={this._onMenuItemClick.bind(this, 'effect.fadeOut')}>Fade Out</MenuItem>
            <MenuItem onClick={this._onMenuItemClick.bind(this, 'effect.louder')}>Louder</MenuItem>
            <MenuItem onClick={this._onMenuItemClick.bind(this, 'effect.quieter')}>Quieter</MenuItem>
            <MenuItem onClick={this._onMenuItemClick.bind(this, 'effect.silence')}>Silence</MenuItem>
          </Menu>
        </RelativeParent>
        <div>
          <Button onClick={this._onMenuItemClick.bind(this, 'help.keyboardShortcuts')}>
            <Text text="shortcuts" />
          </Button>
        </div>
      </Flex>
    );
  }
}
