/**
 * Utilities
 */

export default class Utils {
  /**
   * @param {Function} callable
   * @param {Array.<*>} args
   */
  static invokeAsync(callable, ...args) {
    setTimeout(() => {
      callable(...args);
    }, 0);
  }

  /**
   * @param {Object} obj1
   * @param {Object} obj2
   * @return {boolean}
   */
  static shallowCompare(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let i = 0; i < keys1.length; i++) {
      const key = keys1[i];
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  }

  /**
   * @param {string} name
   * @param {*} [defValue]
   * @return {string|*}
   */
  static getQueryParamByName(name, defValue) {
    const query = window.location.search.substr(1);
    if (query.length === 0) {
      return defValue;
    }
    const params = query.split('&');
    for (let i = 0; i < params.length; i++) {
      const param = params[i].split('=');
      if (param[0] === name) {
        return decodeURIComponent(param[1]);
      }
    }
    return defValue;
  }

  static prependZero(num) {
    if (num >= 10) {
      return num;
    }
    return `0${num}`;
  }

  static secondsToText(secs) {
    let remaining = parseInt(`${secs}`);
    const h = Math.floor(remaining  / (60 * 60));
    remaining -= (60 * 60 * h);
    const m = Math.floor(remaining / 60);
    remaining -= (60 * m);
    const sec = remaining;
    return `${Utils.prependZero(h)}h${Utils.prependZero(m)}m${Utils.prependZero(sec)}s`;
  }

  static exportFieldFromToken(token, field) {
    const pars = token.split('.');
    const payload = JSON.parse(atob(pars[1]));
    return payload[field];
  }

  static debounce(callback, timeout) {
    if (typeof timeout === 'undefined') {
      timeout = 10;
    }
    let timeoutId = null;
    return function() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(callback, timeout);
    };
  }
}
