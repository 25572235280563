import React, {PureComponent} from 'react';

import {CircularProgress} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import Flex from '../../atoms/flex';
import Text from '../../atoms/text';
import SizedBox from '../../atoms/sized-box';
import ApiService from '../../../lib/ApiService';
import './index.scss';

export default class DropZone extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      highlight: false,
      uploading: false,
    };
    this._service = new ApiService();
  }

  _onDragEnter = () => {
    if (this.state.uploading) {
      return;
    }
    this.setState({
      highlight: true,
    });
  };

  _onDragLeave = () => {
    if (this.state.uploading) {
      return;
    }
    this.setState({
      highlight: false,
    });
  };

  _uploadFile(file) {
    const data = new FormData();
    data.append('audio', file);
    this._service.upload('/upload', data)
      .then((res) => {
        this.setState({
          uploading: false,
        });
        if (res && res.fileId) {
          this.props.onUpload(res.fileId, res.url);
        } else {
          alert('Error occurred please try again later');
        }
      });
    this.setState({
      highlight: false,
      uploading: true,
    });
  }

  _onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.dataTransfer || !e.dataTransfer.files || e.dataTransfer.files.length === 0) {
      return;
    }
    const file = e.dataTransfer.files[0];
    if (!file.type || !this._validateMimeType(file.type)) {
      return;
    }
    this._uploadFile(file);
  };

  _onFileChosen = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (!file.type || !this._validateMimeType(file.type)) {
        return;
      }
      this._uploadFile(file);
    }
  };

  _validateMimeType(type) {
    return type.indexOf('audio/') === 0;
  }

  _getTextColor() {
    if (this.state.highlight) {
      return '#919191'
    }
    return '#acacac';
  }

  _getContent() {
    if (this.state.uploading) {
      return (
        <Flex centered="horizontal" col>
          <CircularProgress />
          <SizedBox height={10} />
          <Text size={18} text="Uploading..." color="#acacac" />
        </Flex>
      );
    } else {
      return (
        <div style={{textAlign: 'center'}}>
          <Text size={30} text="Drop an audio file" color={this._getTextColor()} center />
          <br />
          <Text size={30} text="or" color={this._getTextColor()} center />
          <br />
          <Text size={30} text="Click here to select one" color={this._getTextColor()} center />
        </div>
      );
    }
  }

  render() {
    return (
      <div
        onDrop={this._onDrop}
        onDragEnter={this._onDragEnter}
        onDragLeave={this._onDragLeave}
        className={`dropzone ${this.state.highlight ? 'dropzone-highlight' : ''}`}>
        <input
          title=""
          type="file"
          accept="audio/*"
          onChange={this._onFileChosen}
          disabled={this.state.uploading}
          className="dropzone-file-input" />
        <IconButton className="dropzone-close-icon" onClick={this.props.onClose} disabled={this.state.uploading}>
          <CloseIcon />
        </IconButton>
        <Flex fillParent centered>
          {this._getContent()}
        </Flex>
      </div>
    );
  }
}
