import HomePage from '../pages/home';
import LoginPage from '../pages/login';
import AdminScene from "../pages/admin";

import UsersFragment from "../pages/admin/users";
import SessionsFragment from "../pages/admin/sessions";
import TempSessionsFragment from "../pages/admin/tmpSessions";

const routes = {
  home: {path: '/', scene: HomePage},
  preview: {path: '/audio', scene: HomePage},
  login: {path: '/login', scene: LoginPage},
  admin: {path: '/admin', scene: AdminScene, fragment: UsersFragment},
  adminUsers: {path: '/admin/users', scene: AdminScene, fragment: UsersFragment},
  adminSessions: {path: '/admin/sessions', scene: AdminScene, fragment: SessionsFragment},
  adminTempSessions: {path: '/admin/temp-sessions', scene: AdminScene, fragment: TempSessionsFragment},
};

export default routes;
