import React from 'react';
import ReactDOM from 'react-dom';
import App from './system/App';
import './assets/styles/index.scss';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#F1305B',
    },
    secondary: {
      main: '#A5CD39',
    },
  }
});

ReactDOM.render(
    (
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
    )
  ,
  document.getElementById('root')
);
