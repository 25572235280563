import React, {PureComponent} from 'react';
import Flex from "../../components/atoms/flex";
import {Typography, Breadcrumbs, Link, Fab, TextField, Tooltip} from "@material-ui/core";
import ContentLoader from "../../components/atoms/content-loader";
import SizedBox from "../../components/atoms/sized-box";
import ApiService from "../../lib/ApiService";

import {routes} from "../../system/RedirectManager";
import Utils from "../../lib/Utils";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

export default class UsersFragment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      users: null,
      modalOpen: false,
    };
    this._editUser = null;
    this._service = new ApiService();
    this._emailInputRef = React.createRef();
    this._passwordInputRef = React.createRef();
  }

  componentDidMount() {
    this._fetchUsers();
  }

  _fetchUsers() {
    this._service.get('/getUsers')
      .then(response => {
        this.setState({
          users: response,
        });
      });
  }

  _onEditUser = (user) => {
    this._editUser = user;
    this.setState({
      modalOpen: true,
    });
  };

  _onDeleteUser = (user) => {
    // Dont block animation
    Utils.invokeAsync(() => {
      if (window.confirm(`Are you sure you want to delete the user ${user.email}?`)) {
        this._service.delete(`/deleteUser?id=${user.id}`)
          .then(res => {
            if (!res) {
              Utils.invokeAsync(() => {
                alert('Failed to delete user');
              });
              return;
            }
            this._fetchUsers();
          });
      }
    });
  };

  _onCreateUser = () => {
    this.setState({
      modalOpen: true,
    });
  };

  _onSaveUser = () => {
    const editUser = this._editUser;
    const service = editUser ? '/updateUser' : '/createUser';
    const data = {
      email: this._emailInputRef.current.value.trim(),
      password: this._passwordInputRef.current.value.trim(),
    };
    if (editUser) {
      data.id = editUser.id;
    }
    this._service.post(service, data)
      .then((res) => {
        if (!res) {
          Utils.invokeAsync(() => {
            alert('Failed to create user. Please make sure necessary fields are filled and email is not duplicated.');
          });
          return;
        }
        this._fetchUsers();
        this._closeModal();
      });
  };

  _closeModal = () => {
    this._editUser = null;
    this.setState({
      modalOpen: false,
    });
  };

  _getRows() {
    return this.state.users.map(user => {
      return (
        <TableRow key={user.id}>
          <TableCell>{user.id}</TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell align="right">
            <Tooltip title="Edit User">
              <IconButton onClick={this._onEditUser.bind(this, user)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            {user.deletable === 1 && (
              <Tooltip title="Delete User">
                <IconButton onClick={this._onDeleteUser.bind(this, user)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        </TableRow>
      );
    });
  }

  _getModal() {
    const defaultEmail = this._editUser ? this._editUser.email : '';
    const helperText = this._editUser ? 'Leave empty to keep the current password' : undefined;
    const modalTitle = this._editUser ? 'Edit User' : 'Create User';
    return (
      <Dialog open={this.state.modalOpen} onClose={this._closeModal}>
        <DialogTitle id="form-dialog-title">{modalTitle}</DialogTitle>
        <DialogContent style={{width: '400px'}}>
          <TextField
            fullWidth
            type="email"
            margin="dense"
            label="Email Address"
            defaultValue={defaultEmail}
            inputRef={this._emailInputRef}
          />
          <TextField
            fullWidth
            type="password"
            margin="dense"
            label="Password"
            helperText={helperText}
            inputRef={this._passwordInputRef}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this._closeModal} color="default">
            Cancel
          </Button>
          <Button onClick={this._onSaveUser} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    return (
      <Flex col>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" style={{cursor: 'pointer'}} onClick={this.props.redirect.bind(null, routes.admin)}>
            Sas Admin
          </Link>
          <Typography color="textPrimary">Users</Typography>
        </Breadcrumbs>
        <SizedBox height={20} />
        {!this.state.users && (
          <div>
            <ContentLoader />
          </div>
        )}
        {this.state.users && (
          <Flex col>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>EMAIL</TableCell>
                    <TableCell align="right">ACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this._getRows()}
                </TableBody>
              </Table>
            </TableContainer>
            <Tooltip style={{position: 'absolute', bottom: 20, right: 20}} title="Create User">
              <Fab color="primary" onClick={this._onCreateUser}>
                <AddIcon />
              </Fab>
            </Tooltip>
          </Flex>
        )}
        {this._getModal()}
      </Flex>
    );
  }
}
