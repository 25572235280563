import React, {PureComponent} from 'react';
import {CircularProgress, Grid} from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import Utils from '../../lib/Utils';
import EventBus from '../../lib/EventBus';
import ApiService from '../../lib/ApiService';
import Flex from '../../components/atoms/flex';
import Text from '../../components/atoms/text';
import Waves from '../../components/molecules/waves';
import SessionManager from '../../lib/SessionManager';
import Header from '../../components/organisms/header';
import SizedBox from '../../components/atoms/sized-box';
import AppMenu from '../../components/organisms/app-menu';
import Controls from '../../components/organisms/controls';
import Comments from '../../components/organisms/comments';
import DropZone from '../../components/molecules/dropzone';
import MarkerLine from '../../components/organisms/marker-line';
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';
import './index.scss';

export default class HomePage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      refId: null,
      comments: [],
      fileId: null,
      fileUrl: null,
      now: Date.now(),
      playing: false,
      markerLine: {
        left: 0,
        width: 0,
      },
      dropzoneOpen: true,
      saveModalOpen: false,
      commentReplyId: null,
      commentEditMode: null,
      sessionUrlCopied: false,
      commentModalOpen: false,
      downloadModalOpen: false,
      shortcutsModalOpen: false,
      shareSessionModalOpen: false,
      sessionInfo: this._getSessionInfo(),
      portrait: this._isMobilePortrait(),
      loading: window.location.href.indexOf('/audio?id=') > 0,
    };
    this._emailInputRef = React.createRef();
    this._shareCommentsInputRef = React.createRef();
    this._commentInputRef = React.createRef();
    this._cNameInputRef = React.createRef();
    this._fullNameInputRef = React.createRef();
    this._waves = React.createRef();
    this._eventBus = new EventBus();
    this._service = new ApiService();
    this._registerMenuEvents();
    this._registerControlEvents();
    this._registerShortcuts();
  }

  componentDidMount() {
    if (window.location.href.indexOf('/audio?id=') > 0) {
      const id = Utils.getQueryParamByName('id');
      const p1 = this._service.get(`/audio?id=${id}`);
      const p2 = this._fetchComments(id);
      Promise.all([p1, p2]).then((results) => {
        if (!results[0]) {
          window.location.href = '/';
          return;
        }
        this.setState({
          fileId: results[0].fileId,
          fileUrl: results[0].url,
          comments: results[1],
          loading: false,
          dropzoneOpen: false,
        });
      });
    }
    window.addEventListener("resize", this._checkOrientation, false);
    window.addEventListener("orientationchange", this._checkOrientation, false);
    setInterval(this._checkOrientation, 2000);
  }

  _checkOrientation = () => {
    const portrait = this._isMobilePortrait();
    if (this.state.portrait !== portrait) {
      this.setState({
        portrait: portrait,
      });
    }
  };

  _isMobilePortrait() {
    if (window.innerWidth <= 960) {
      return window.matchMedia("(orientation: portrait)").matches;
    }
    return false;
  }

  _getSessionInfo() {
    if (SessionManager.hasSession()) {
      const session = SessionManager.getSession();
      if (session.isGuest()) {
        return {
          guest: true,
          name: `${session.getFullName()} (${session.getCompanyName()})`,
        };
      }
      const email = session.getEmail();
      return {
        guest: false,
        name: email.split('@')[1].split('.')[0],
      };
    }
    return {
      guest: true,
      name: 'Unknown',
      unknown: true,
    };
  }

  _shouldRedirect() {
    return SessionManager.getSession().isGuest() &&
      window.location.href.indexOf('/audio?id=') < 0;
  }

  _actionsAllowed() {
    const s = this.state;
    return s.fileId !== null && s.fileUrl !== null && s.dropzoneOpen === false &&
        s.saveModalOpen === false && s.downloadModalOpen === false && s.commentModalOpen === false &&
        s.shortcutsModalOpen === false && s.shareSessionModalOpen === false && s.loading === false &&
        !s.sessionInfo.unknown;
  }

  _registerShortcuts() {
    document.addEventListener('keydown', (e) => {
      if (!this._actionsAllowed()) {
        return;
      }
      switch (e.key) {
        case 'c':
          e.preventDefault();
          this._applyEffect('trim');
          break;
        case 's':
          e.preventDefault();
          this._callWavesApi('addRange');
          break;
        case 'z':
          e.preventDefault();
          this._callWavesApi('zoom');
          break;
        case 'a':
          e.preventDefault();
          this._callWavesApi('zoomOut');
          break;
        case 'm':
          e.preventDefault();
          this.setState({
            commentModalOpen: true,
          });
          break;
        case ' ':
          e.preventDefault();
          this._callWavesApi(this.state.playing ? 'pause' : 'play');
          break;
        default:
          // do nothing
      }
    });
  }

  _fetchComments(fileId) {
    return this._service.get(`/listComments?type=listComments&audioId=${fileId}`);
  }

  _registerMenuEvents() {
    this._eventBus.on('menu.action', (event) => {
      switch (event.getDetails().actionType) {
        case 'file.new':
          window.location.href = '/';
          break;
        case 'file.open':
          if (SessionManager.getSession().isGuest()) {
            return;
          }
          this._onDragEnter();
          break;
        case 'file.download':
          this.setState({
            downloadModalOpen: true,
          });
          break;
        case 'file.save':
          this._saveChanges();
          break;
        case 'file.shareSession':
          this.setState({
            shareSessionModalOpen: true,
          });
          break;
        case 'effect.fadeIn':
        case 'effect.fadeOut':
        case 'effect.silence':
        case 'effect.louder':
        case 'effect.quieter':
          this._applyEffect(event.getDetails().actionType.split('.')[1]);
          break;
        case 'help.keyboardShortcuts':
          this.setState({
            shortcutsModalOpen: true,
          });
          break;
        default:
          console.log(event);
      }
    });
  }

  _applyEffect(type) {
    if (!this._waves.current) {
      return;
    }
    if (!this._actionsAllowed) {
      return;
    }
    const range = this._waves.current.getSelectedRange();
    if (!range) {
      return;
    }
    const duration = this._waves.current.getDuration();
    let idQuery;
    if (this.state.refId) {
      idQuery = `refId=${this.state.refId}`;
    } else {
      idQuery = `id=${this.state.fileId}`;
    }
    this._service.get(`/applyEffect?type=${type}&start=${range.start}&end=${range.end}&duration=${duration}&${idQuery}`)
      .then((response) => {
        this.setState({
          fileUrl: response.url,
          refId: response.fileId,
        });
      });
  }

  _registerControlEvents() {
    this._eventBus.on('control.action', (event) => {
      if (!this._actionsAllowed) {
        return;
      }
      switch (event.getDetails().actionType) {
        case 'controls.play':
          this._callWavesApi('play');
          break;
        case 'controls.pause':
        case 'controls.stop':
          this._callWavesApi('pause');
          break;
        case 'controls.zoom':
          this._callWavesApi('zoom');
          break;
        case 'controls.zoomOut':
          this._callWavesApi('zoomOut');
          break;
        case 'controls.seekStart':
          this._callWavesApi('seekStart');
          break;
        case 'controls.select':
          this._callWavesApi('addRange');
          break;
        case 'controls.trim':
          this._applyEffect(event.getDetails().actionType.split('.')[1]);
          break;
        case 'controls.saveShare':
          this.setState({
            saveModalOpen: true,
          });
          break;
        case 'controls.mark':
          this.setState({
            commentModalOpen: true,
          });
          break;
        default:
          console.log(event);
      }
    });
  }

  _callWavesApi(method, ...params) {
    if (!this._waves.current) {
      return;
    }
    this._waves.current[method](...params);
  }

  _onDragEnter = () => {
    this.setState({
      dropzoneOpen: true,
    });
  };

  _onDragLeave= () => {
    this.setState({
      dropzoneOpen: false,
    });
  };

  _onUpload = (fileId, fileUrl) => {
    this.setState({
      fileId: fileId,
      fileUrl: fileUrl,
      dropzoneOpen: false,
      refId: null,
      comments: [],
      loading: false,
    });
  };

  _getDuration() {
    if (!this._waves.current) {
      return 0;
    }
    return this._waves.current.getDuration();
  }

  _getPosition() {
    if (!this._waves.current) {
      return 0;
    }
    return this._waves.current.getPosition();
  }

  _forceRender = () => {
    // Force re-render
    const details = this._waves.current.getViewDetails();
    this.setState({
      now: Date.now(),
      markerLine: {
        left: details.left,
        width: details.width,
      },
    });
  };

  _onModalClose = (type) => {
    this.setState({
      [type]: false,
      commentReplyId: null,
      commentEditMode: null,
      sessionUrlCopied: false,
    });
  };

  _getModal() {
    let content;
    if (!this.state.fileId) {
      content = (
        <span>Please upload a file first.</span>
      );
    } else {
      content = (
        <p>
          <span>Click the button below to download the latest audio file.</span>
          <br />
          <br/>
          <Button variant="outlined" color="default" onClick={() => {window.open(this.state.fileUrl,'_blank')}}>Download</Button>
        </p>
      );
    }

    return (
      <Dialog open={this.state.downloadModalOpen} onClose={this._onModalClose.bind(this, 'downloadModalOpen')}>
        <DialogTitle id="form-dialog-title">Sharable Link</DialogTitle>
        <DialogContent style={{width: '400px'}}>
          {content}
        </DialogContent>
        <DialogActions>
          <Button onClick={this._onModalClose.bind(this, 'downloadModalOpen')} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _getSessionShareModal() {
    let content;
    if (!this.state.fileId) {
      content = (
        <span>Please upload a file first.</span>
      );
    } else {
      content = (
        <p>
          <span>Click the button below to copy the session url.</span>
          <br />
          <br/>
          <CopyToClipboard
            text={`${window.location.origin}/audio?id=${this.state.fileId}`}>
            <Button variant="outlined" color="default" onClick={() => this.setState({sessionUrlCopied: true})}>Copy</Button>
          </CopyToClipboard>
          {this.state.sessionUrlCopied && (
            <small style={{marginLeft: '10px'}}>Copied!</small>
          )}
        </p>
      );
    }

    return (
      <Dialog open={this.state.shareSessionModalOpen} onClose={this._onModalClose.bind(this, 'shareSessionModalOpen')}>
        <DialogTitle id="form-dialog-title">Share Session URL</DialogTitle>
        <DialogContent style={{width: '400px'}}>
          {content}
        </DialogContent>
        <DialogActions>
          <Button onClick={this._onModalClose.bind(this, 'shareSessionModalOpen')} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _onSaveAndShare = () => {
    this._onModalClose('saveModalOpen');
    const email = this._emailInputRef.current.value.trim();
    const comments = this._shareCommentsInputRef.current.value.trim();
    this._saveChanges(email, comments);
  };

  _saveChanges(email, comments) {
    let query = '';
    if (this.state.refId) {
      query += `&refId=${this.state.refId}`;
    }
    this._service.post(`/saveAudio?id=${this.state.fileId}${query}`, {
      comments: (comments && comments.length > 0) ? comments : null,
      email: (email && email.length > 0) ? email : null,
    })
      .then((response) => {
        if (!email) {
          alert('Session is saved');
        } else {
          alert('Session is saved and shared');
        }
        this.setState({
          refId: null,
        });
      });
  }

  _getSaveShareModal() {
    return (
        <Dialog open={this.state.saveModalOpen} onClose={this._onModalClose.bind(this, 'saveModalOpen')}>
          <DialogTitle id="form-dialog-title">Save & Share</DialogTitle>
          <DialogContent style={{width: '400px'}}>
            <span>Leave email empty if you only need to save the changes.</span>
            <SizedBox height={5} />
            <TextField
              color="primary"
              inputRef={this._emailInputRef}
              margin="dense"
              label="Email Address"
              type="email"
              fullWidth
            />
            <TextField
              color="primary"
              inputRef={this._shareCommentsInputRef}
              margin="dense"
              label="Comments"
              type="text"
              fullWidth
              multiline
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this._onModalClose.bind(this, 'saveModalOpen')} color="default">
              Cancel
            </Button>
            <Button onClick={this._onSaveAndShare} color="default">
              Save & Share
            </Button>
          </DialogActions>
        </Dialog>
    );
  };

  _getShortcutsModal() {
    return (
      <Dialog open={this.state.shortcutsModalOpen} onClose={this._onModalClose.bind(this, 'shortcutsModalOpen')}>
        <DialogTitle id="form-dialog-title">Keyboard Shortcuts</DialogTitle>
        <DialogContent style={{width: '300px'}}>
          <Flex col>
            <Flex row spaced>
              <span>Cut</span>
              <strong>c</strong>
            </Flex>
            <br />
            <Flex row spaced>
              <span>Select</span>
              <strong>s</strong>
            </Flex>
            <br />
            <Flex row spaced>
              <span>Zoom In</span>
              <strong>z</strong>
            </Flex>
            <br />
            <Flex row spaced>
              <span>Zoom Out</span>
              <strong>a</strong>
            </Flex>
            <br />
            <Flex row spaced>
              <span>Add Marker</span>
              <strong>m</strong>
            </Flex>
            <br />
            <Flex row spaced>
              <span>Play/Pause</span>
              <strong>spacebar</strong>
            </Flex>
          </Flex>
        </DialogContent>
        <DialogActions>
          <Button onClick={this._onModalClose.bind(this, 'shortcutsModalOpen')} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _onAddComment = () => {
    const author = this.state.sessionInfo.name.trim();
    const comment = this._commentInputRef.current.value.trim();
    if (author.length === '' || comment.length === '') {
      alert('Please fill out all the fields');
      return;
    }
    const editComment = this.state.commentEditMode;
    const method = editComment !== null ? 'editComment' : 'addComment';
    const pos = this._waves.current.getPosition();
    const fileId = this.state.fileId;
    this._service.post(`/addComment?type=${method}`, {
      id: editComment ? editComment.id : undefined,
      pos: pos,
      author: author,
      text: comment,
      audioId: fileId,
      parentId: this.state.commentReplyId,
    }).then(() => {
      this._fetchComments(fileId)
        .then((comments) => {
          this.setState({
            commentModalOpen: false,
            comments: comments,
            commentReplyId: null,
            commentEditMode: null,
          });
        });
    });
  };

  _getCommentModal() {
    if (this.state.commentModalOpen) {
      this._waves.current.pause();
    }

    const onKeyDown = (e) => {
      // Capture enter hit
      if (e.keyCode === 13) {
        this._onAddComment();
      }
    };

    const editComment = this.state.commentEditMode;
    const editMode = editComment !== null;
    const modalTitle = editMode ? 'Edit Marker' : 'Add Marker';

    return (
        <Dialog open={this.state.commentModalOpen} onClose={this._onModalClose.bind(this, 'commentModalOpen')}>
          <DialogTitle id="form-dialog-title">{modalTitle}</DialogTitle>
          <DialogContent style={{width: '400px'}}>
            <TextField
              color="primary"
              inputRef={this._commentInputRef}
              margin="dense"
              label="Comment"
              type="text"
              fullWidth
              autoFocus
              onKeyDown={onKeyDown}
              defaultValue={editMode ? editComment.text : ''}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this._onModalClose.bind(this, 'commentModalOpen')} color="default">
              Cancel
            </Button>
            <Button onClick={this._onAddComment} color="default">
              Save
            </Button>
          </DialogActions>
        </Dialog>
    );
  };

  _onUpdateSessionInfo = () => {
    const cName = this._cNameInputRef.current.value;
    const fullName = this._fullNameInputRef.current.value;
    if (cName.length === 0 || fullName.length === 0) {
      alert('Please fill out all the fields');
      return;
    }
    SessionManager.setSession({
      companyName: cName,
      fullName: fullName,
    }, true);
    this.setState({
      sessionInfo: this._getSessionInfo(),
    });
  };

  _getSessionInfoModal() {
    return (
      <Dialog open={!!this.state.sessionInfo.unknown} onClose={() => { return false} }>
        <DialogTitle id="form-dialog-title">Update Your Info</DialogTitle>
        <DialogContent style={{width: '400px'}}>
          <TextField
            color="primary"
            inputRef={this._cNameInputRef}
            margin="dense"
            label="Company Name"
            type="text"
            fullWidth />
          <TextField
            color="primary"
            inputRef={this._fullNameInputRef}
            margin="dense"
            label="Full Name"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this._onUpdateSessionInfo} color="default">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _onCommentReply = (comment) => {
    const replyId = comment.parentId ? comment.parentId : comment.id;
    this.setState({
      commentModalOpen: true,
      commentReplyId: replyId,
    });
  };

  _onCommentDelete = (comment) => {
    this._service.delete(`/deleteComment?type=deleteComment&id=${comment.id}`)
      .then(() => {
        const comments = [];
        this.state.comments.forEach((cm) => {
          if (cm.id !== comment.id && cm.parentId !== comment.id) {
            comments.push(cm);
          }
        });
        this.setState({
          comments: comments,
        });
      });
  };

  _onCommentEdit = (comment) => {
    this.setState({
      commentModalOpen: true,
      commentEditMode: comment,
    })
  };

  _onCommentCheck = (comment) => {
    if (SessionManager.getSession().isGuest()) {
      return;
    }
    this._service.get(`/closeComment?type=closeComment&id=${comment.id}`)
      .then(() => {
        comment.completed = 1;
        this.setState({
          comments: JSON.parse(JSON.stringify(this.state.comments)),
        });
      });
  };

  _onPlayStateChange = (playing) => {
    this.setState({
      playing: playing,
    });
  };

  _onSeek = (pos) => {
    this._callWavesApi('seekTo', pos);
  };

  _getOrientationView() {
    return (
      <div className="portrait-orientation-container">
        <Flex fillHeight centered="both" col>
          <ScreenRotationIcon className="portrait-icon" />
          <SizedBox height={20} />
          <Text size={20} color="#ffffff" text="Voor optimale prestatie van de website adviseren wij uw toestel te draaien." />
        </Flex>
      </div>
    );
  }

  render() {
    if (this._shouldRedirect()) {
      window.location.href = '/login';
      return null;
    }
    const isPortrait = this._isMobilePortrait();
    const duration = this._waves.current ? this._waves.current.getDuration() : 0;
    const isMobileOrTablet = window.innerWidth <= 1280;
    return (
      <div id="app-container">
        {this._getModal()}
        {this._getSaveShareModal()}
        {this._getCommentModal()}
        {this._getSessionInfoModal()}
        {this._getShortcutsModal()}
        {this._getSessionShareModal()}
        {isPortrait && (
          this._getOrientationView()
        )}
        <Grid container spacing={3} style={{height: '100%'}}>
          <Grid item xs={12} lg={9}>
            <Flex fillHeight={!isMobileOrTablet} col>
              <Header />
              <SizedBox height={30} />
              <div>
                <AppMenu dispatcher={this._eventBus} />
                <MarkerLine left={this.state.markerLine.left} width={this.state.markerLine.width} duration={duration} markers={this.state.comments} onSeek={this._onSeek} />
              </div>
              <div className={`audio-wave-container ${this.state.dropzoneOpen ? 'hidden' : ''}`}>
                <Waves src={this.state.fileUrl} ref={this._waves} onReady={this._forceRender} onProgress={this._forceRender} onPlayStateChange={this._onPlayStateChange} onScroll={this._forceRender} />
              </div>
              {this.state.dropzoneOpen && !this.state.loading && (
                <div className="dropzone-container">
                  <DropZone onClose={this._onDragLeave} onUpload={this._onUpload} />
                </div>
              )}
              {this.state.loading && (
                <Flex centered col fillParent>
                  <CircularProgress />
                </Flex>
              )}
              <div className={`controls-container ${this.state.dropzoneOpen ? 'hidden' : ''}`}>
                <Controls playing={this.state.playing} dispatcher={this._eventBus} duration={this._getDuration()} pos={this._getPosition()} />
              </div>
            </Flex>
          </Grid>
          <Grid item xs={12} lg={3} style={{maxHeight: '100%'}}>
            <Comments
              onReply={this._onCommentReply}
              onEdit={this._onCommentEdit}
              onDelete={this._onCommentDelete}
              onCheck={this._onCommentCheck}
              comments={this.state.comments}
              onSeek={this._onSeek} />
          </Grid>
        </Grid>
      </div>
    );
  }
}
