import React, {PureComponent} from 'react';

import Utils from '../../../lib/Utils';
import Flex from '../../atoms/flex';

import './index.scss';

const WaveSurfer = require('wavesurfer.js');
const RegionPlugin = require('wavesurfer.js/dist/plugin/wavesurfer.regions');

export default class Waves extends PureComponent {
  constructor(props) {
    super(props);
    this._container = React.createRef();
    this.state = {
      ready: false,
      now: Date.now(),
    };
    this._zoom = 50;
    this._waveSurfer = null;
  }

  afterRender() {
    if (this.state.ready) {
      return;
    }
    if (!this._container.current) {
      Utils.invokeAsync(() => {
        this.setState({
          now: Date.now(),
        });
      });
    } else {
      if (!this.props.src) {
        return;
      }
      if (this._waveSurfer) {
        this._waveSurfer.destroy();
      }
      let height = 800;
      if (window.innerWidth <= 1000) {
        height = 500;
      } else if (window.innerWidth <= 700) {
        height = 350;
      } else if (window.innerWidth <= 600) {
        height = 200;
      } else if (window.innerWidth <= 500) {
        height = 150;
      }
      this._waveSurfer = WaveSurfer.create({
        container: this._container.current,
        waveColor: '#F1305B',
        progressColor: '#a72d4e',
        scrollParent: false,
        cursorColor: '#777777',
        height: height,
        partialRender: true,
        responsive: true,
        plugins: [
          RegionPlugin.create({
            dragSelection: true
          }),
        ],
      });
      this._waveSurfer.on('ready', this.props.onReady);
      this._waveSurfer.on('audioprocess', this.props.onProgress);
      this._waveSurfer.on('region-created', this._clearRegions);
      this._waveSurfer.on('seek', () => {
        this.props.onProgress();
        this._clearRegions();
      });
      this._waveSurfer.on('play', this.props.onPlayStateChange.bind(null, true));
      this._waveSurfer.on('pause', this.props.onPlayStateChange.bind(null, false));
      this._waveSurfer.on('scroll', this.props.onScroll);
      this._waveSurfer.load(this.props.src);
    }
  }

  getViewDetails() {
    if (!this._container.current) {
      return null;
    }
    const wave = this._container.current.firstElementChild;
    return {
      width: wave.scrollWidth,
      left: wave.scrollLeft,
    };
  }

  _clearRegions = () => {
    // Clear old regions if exist
    this._waveSurfer.clearRegions();
  };

  getDuration() {
    if (this._waveSurfer) {
      return this._waveSurfer.getDuration();
    }
    return 0;
  }

  getPosition() {
    if (this._waveSurfer) {
      return this._waveSurfer.getCurrentTime();
    }
    return 0;
  }

  play() {
    if (this._waveSurfer) {
      this._waveSurfer.play();
    }
  }

  seekStart() {
    if (this._waveSurfer) {
      this._waveSurfer.seekAndCenter(0);
    }
  }

  seekTo(pos) {
    if (this._waveSurfer) {
      const duration = this.getDuration();
      const percentage = (100 * pos) / duration;
      this._waveSurfer.seekAndCenter(percentage / 100);
    }
  }

  pause() {
    if (this._waveSurfer) {
      this._waveSurfer.pause();
    }
  }

  zoom() {
    if (this._waveSurfer) {
      this._zoom += 10;
      this._waveSurfer.zoom(this._zoom);
    }
  }

  zoomOut() {
    if (this._waveSurfer && this._zoom > 0) {
      this._zoom -= 10;
      this._waveSurfer.zoom(this._zoom);
    }
  }

  getSelectedRange() {
    const regions = this._waveSurfer.regions.list;
    if (Object.keys(regions).length > 0) {
      const region = regions[Object.keys(regions)[0]];
      return {
        start: region.start,
        end: region.end,
      };
    }
    return null;
  }

  addRange() {
    if (!this._waveSurfer) {
      return;
    }
    let start = this.getPosition();
    let end = start + 3;
    if (end > this.getDuration()) {
      end = this.getDuration();
    }
    this._waveSurfer.addRegion({
      start: start,
      end: end,
    });
  }

  render() {
    Utils.invokeAsync(this.afterRender.bind(this));
    return (
      <Flex centered fillHeight row>
        <div className="waves-container">
          <div ref={this._container} />
        </div>
      </Flex>
    )
  }
}
