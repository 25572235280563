import React, {PureComponent} from 'react';
import Flex from "../../components/atoms/flex";
import {Breadcrumbs, Link, Typography, Tooltip} from "@material-ui/core";
import SizedBox from "../../components/atoms/sized-box";
import ContentLoader from "../../components/atoms/content-loader";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {routes} from "../../system/RedirectManager";
import ApiService from "../../lib/ApiService";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import InfoIcon from '@material-ui/icons/Info';

const moment = require('moment');

export default class SessionsFragment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sessions: null,
    };
    this._service = new ApiService();
  }

  componentDidMount() {
    this._fetchSessions();
  }

  _fetchSessions() {
    this._service.get('/getSessions')
      .then(response => {
        this.setState({
          sessions: response,
        });
      });
  }

  _onOpenSession = (sessionId) => {
    window.open(`/audio?id=${sessionId}`, '_blank');
  };

  _onDeleteSession = (sessionId) => {
    if (window.confirm('Main session and attached temporary session (if exists) will be deleted forever. Do you want to proceed?')) {
      this._service.delete(`/deleteSession?id=${sessionId}`)
        .then(() => {
          this._fetchSessions();
        });
    }
  };

  _onUnsavedClick = () => {
    this.props.redirect(routes.adminTempSessions);
  };

  _getRows() {
    return this.state.sessions.map(session => {
      const mTime = moment(session.createdAt);
      return (
        <TableRow key={session.id}>
          <TableCell>{session.originalFileName}</TableCell>
          <TableCell>{mTime.format('MM/DD/YYYY, hh:mm a')}</TableCell>
          <TableCell align="right">
            {session.unsaved && (
              <Tooltip title="There are unsaved changes attached to this session. If you lost the session or you are done with it, you can remove the session and temporary audio file from your server. Click here to see the temporary sessions attached to the main sessions.">
                <IconButton color="primary" onClick={this._onUnsavedClick}>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Open Session">
              <IconButton onClick={this._onOpenSession.bind(this, session.id)}>
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Session">
              <IconButton onClick={this._onDeleteSession.bind(this, session.id)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    return (
      <Flex col>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" style={{cursor: 'pointer'}} onClick={this.props.redirect.bind(null, routes.admin)}>
            Sas Admin
          </Link>
          <Typography color="textPrimary">Sessions</Typography>
        </Breadcrumbs>
        <SizedBox height={20} />
        {!this.state.sessions && (
          <div>
            <ContentLoader />
          </div>
        )}
        {this.state.sessions && (
          <Flex col>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>FILE NAME</TableCell>
                    <TableCell>CREATED AT</TableCell>
                    <TableCell align="right">ACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this._getRows()}
                </TableBody>
              </Table>
            </TableContainer>
          </Flex>
        )}
      </Flex>
    );
  }
}
