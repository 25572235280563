class AppSession {
  constructor(session) {
    this._session = session;
  }

  // Guest
  isGuest() {
    return this._session.guest;
  }

  // Guest
  getCompanyName() {
    return this._session.companyName;
  }

  // Guest
  getFullName() {
    return this._session.fullName;
  }

  isAdmin() {
    return this._session.admin;
  }

  getToken() {
    return this._session.token;
  }

  getEmail() {
    return this._session.email;
  }
}

export default class SessionManager {
  static getSession() {
    if (SessionManager.hasSession()) {
      const sess = localStorage.getItem('sas.session');
      return new AppSession(JSON.parse(sess));
    }
    return new AppSession({
      companyName: 'Unknown',
      guest: true,
      fullName: 'Unknown',
    });
  }

  static setSession(sess, guest) {
    localStorage.setItem('sas.session', JSON.stringify({
      ...sess,
      guest: guest,
    }))
  }

  static hasSession() {
    return localStorage.getItem('sas.session') !== null;
  }

  static clearSession() {
    localStorage.removeItem('sas.session');
  }
}
