import React, {PureComponent} from 'react';
import './index.scss';

/**
 * Flex
 */
export default class Flex extends PureComponent {
  /**
   * Props
   *  - col
   *  - row
   *  - centered={both/vertical/horizontal}
   *  - spaced={between/around}
   *
   * spaced=between is equal to spaced=true
   * centered=both is equal to centered=true
   *
   * @return {string}
   * @private
   */
  _getContainerClass() {
    const classNames = [];
    classNames.push('flex-box');
    let isCol = true;
    if (this.props.row) {
      isCol = false;
      classNames.push('flex-box-row');
    }
    if (isCol) {
      classNames.push('flex-box-col');
    }
    if (this.props.centered) {
      const val = this.props.centered;
      switch (val) {
        case true:
        case 'both':
          classNames.push('flex-box-center-j');
          classNames.push('flex-box-center-a');
          break;
        case 'vertical':
          if (isCol) {
            classNames.push('flex-box-center-j');
          } else {
            classNames.push('flex-box-center-a');
          }
          break;
        case 'horizontal':
          if (isCol) {
            classNames.push('flex-box-center-a');
          } else {
            classNames.push('flex-box-center-j');
          }
          break;
        default:
          break;
      }
    }
    if (this.props.spaced) {
      if (this.props.spaced === true || this.props.spaced === 'between') {
        classNames.push('flex-box-space-b');
      } else {
        classNames.push('flex-box-space-a');
      }
    }
    if (this.props.fillParent) {
      classNames.push('flex-box-fill-parent');
    }
    if (this.props.fillWindow) {
      classNames.push('flex-box-fill-window');
    }
    if (this.props.className) {
      classNames.push(this.props.className);
    }
    if (this.props.fillHeight) {
      classNames.push('fill-height');
    }
    if (this.props.align) {
      classNames.push(`flex-box-align-${this.props.align}`);
    }
    return classNames.join(' ');
  }

  /**
   * @return {*}
   */
  render() {
    const style = this.props.style || {};
    return (
      <div style={style} className={this._getContainerClass()}>
        {this.props.children}
      </div>
    );
  }
}
