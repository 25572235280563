import EventBus from '../lib/EventBus';
import routeConfig from '../config/routeConfig';
import {EventFactory} from "../lib/Event";

const REDIRECT_EVENT = 'redirect';

export {routeConfig as routes};

/**
 * App router
 */
export class RedirectManager {
  static _instance = null;

  /**
   */
  constructor() {
    this._bus = new EventBus();
  }

  /**
   * @return {RedirectManager}
   */
  static getInstance() {
    if (RedirectManager._instance === null) {
      RedirectManager._instance = new RedirectManager();
    }
    return RedirectManager._instance;
  }

  /**
   * @param {Function} callable
   */
  onRedirect(callable) {
    this._bus.on(REDIRECT_EVENT, callable);
  }

  /**
   * @param {Function} callable
   */
  offRedirect(callable) {
    this._bus.off(REDIRECT_EVENT, callable);
  }

  /**
   * @param {Object} to
   * @param {Object} [query]
   */
  redirect(to, query) {
    const event = EventFactory.create(REDIRECT_EVENT, {
      to: to,
      query: query,
    });
    this._bus.publish(event);
  }
}
